.facilitiesDescription {
  padding: 0;
  margin: 0 0 0 8px;
  color: #fff;
  font-family: "Poppins";
}
.card {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}
.customCard {
  z-index: 4;
}
@media (max-width: 540px) {
  .facilitiesDescription {
    font-size: 12px;
  }
  .titleImg {
    width: 10%;
    height: 10%;
  }
}
@media (min-width: 540px) and (max-width: 700px) {
  .facilitiesDescription {
    font-size: 14px;
  }
  .titleImg {
    width: 12%;
    height: 12%;
  }
}
