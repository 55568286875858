.appBarContainer {
  display: flex;
  width: 100%;
}

.logo {
  height: 95px;
  width: 80.1px;
  margin-left: 148px;
  margin-right: 74px;
  margin-block: 14px;
}

.navItem {
  margin-right: 74px;
  cursor: pointer;
  color: black;
  margin-top: 28px;
}
.textDiv {
  margin-left: 148px;
}

.contactButtonContainer {
  display: flex;
  margin-right: 148px;
  margin-top: 20px;
  height: 39px;
  width: 167px;
  border-radius: 20px;
  background-color: #ffc300;
  justify-content: center;
  align-items: center;
}

.guardianText {
  font-size: 44px;
  color: #ffc300;
  padding: 0;
  margin: 0;
  font-family: "Khula";
  font-weight: 600;
  height: 47px;
}

.joinUsText {
  font-size: 67px;
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: #001d3d;
  font-family: "Khula";
}
.securityBox {
  display: flex;
  flex: 1;
}
.image-card {
  position: relative;
  width: 330px;
  height: 426px;
  border-radius: 18px;
  margin: 8px;
  overflow: hidden; /* Ensure content doesn't overflow the rounded corners */
}

.image-card__image {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  object-fit: cover; /* Ensure image covers the area */
}

.image-card__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 18px;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 23, 61, 0.7) 100%
  );
  text-align: left; /* Center the text horizontally */
}

.image-card__title {
  margin: 0;
  font-size: 1.2em;
  margin-bottom: 12px;
}

.image-card__description {
  margin: 0;
  font-size: 0.9em;
}
.blueSecurityText {
  font-weight: bolder;
  color: #001f40;
  font-size: 122px;
  position: absolute;
  transform: rotate(90deg);
  top: 180px;
  right: -210px;
}
.guardImg {
  width: 414px;
  height: 607px;
  margin-left: -178px;
  z-index: 1;
}

.image-box {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the gap between rows as needed */
}

.row {
  display: flex;
  gap: 20px; /* Adjust the gap between images in the same row as needed */
}

.image {
  width: 316px;
  height: 348px;
  border-radius: 15px;
}

.image-large {
  width: 651px;
  height: 348px;
  border-radius: 15px;
}
.cardChooseUs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px 20px;
  width: 300px; /* Adjust the width as needed */
  margin: 10px;
}

.card-image {
  width: 24px;
  height: 24px;
  border-radius: 15px 15px 0 0;
}

.card-title {
  font-size: 1.5em;
  margin: 10px 0;
}

.card-description {
  font-size: 1em;
  color: #666;
}
.guardFormDiv {
  width: 478px;
  border-radius: 24px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-left: 148px;
}
.bookGuard {
  position: absolute;
  color: #fff;
  font-weight: bold;
  font-size: 56px;
  margin-top: 340px;
  font-family: Khula;
  line-height: 60px;
}
.facilityCard {
  justify-content: center;
  flex: 1;
  align-items: center;
  display: flex;
}

@media (max-width: 540px) {
  .guardianText {
    font-size: 28px;
  }
  .joinUsText {
    font-size: 40px;
  }
  .bookGuard {
    font-size: 36px;
  }
}
@media (max-width: 600px) {
  .blueDiv {
    display: none; /* Hide contact button container on small screens if needed */
  }
}
@media (max-width: 500px) {
  .image {
    width: 158px;
    height: 160px;
  }
  .image-large {
    width: 340px;
    height: 160px;
  }
}
@media (min-width: 500px) and (max-width: 700px) {
  .image {
    width: 216px;
    height: 248px;
  }
  .image-large {
    width: 451px;
    height: 248px;
  }
}
@media (max-width: 800px) {
  .securityBox {
    flex-direction: column;
  }
  .guardImg {
    margin-left: 4%;
    width: 50%;
    height: 60%;
  }
  .guardFormDiv {
    width: 75%;
    margin-bottom: 42px;
  }
}
@media (max-width: 1100px) {
  .whyChooseMainBox {
    flex-direction: column;
    align-items: center;
  }
  .wantGuardGrid {
    flex-direction: column;
  }
  .whyUsDiv {
    margin-top: 42px;
    margin-inline: 10%;
  }
  .cardChooseUs {
    width: 90%;
    margin: 0px;
    padding: 5px 10px;
  }
  .logo {
    margin-left: 13%;
  }
  .textDiv {
    margin-left: 13%;
  }
  .guardFormDiv {
    margin-left: 13%;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .securityBox {
    flex-direction: column;
  }
  .guardImg {
    margin-left: 84px;
    width: 380px;
    height: 540px;
  }
  .guardFormDiv {
    width: 75%;
    margin-bottom: 42px;
  }
}
